require('../sass/runbundle.scss');

// --------------------------------------------------------------------------

$(document).bind("tooglePianoId", function(e, pianoUser) {
    if (null !== pianoUser && tp.pianoId.isUserValid()) {
        $("[data-ssousername='true']").html(pianoUser.firstName);
        $(".userinfo").removeClass("d-none");
        $("#btn-login").addClass("d-none");
        $("#btn-logout").removeClass("d-none");
    } else {
        $(".userinfo").addClass("d-none");
        $("#btn-login").removeClass("d-none");
        $("#btn-logout").addClass("d-none");
    }
});

$("[data-ssoaction='pre-logout']").click(function (e) {
    e.preventDefault();
    let href = $(this).attr('data-href');

    tp.pianoId.logout(function(){
        $(document).trigger("tooglePianoId", null);
        if (null !== href) {
            window.location = href;
        }
    });
    return false;
});

tp = window.tp || [];
tp.push(["init", function () {
    // Disparamos el evento para que refleje estado del usaurio actual
    $(document).trigger("tooglePianoId", tp.pianoId.getUser());
}]);